import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
// import { saveAs } from "file-saver";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PromotionSettlement = () => {
  const [data, setData] = useState([]);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://api.holocabs.in/driverConfirm/ride-history", {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const filteredData = response.data.filter(
          (item) => !item.details.isSettlementDone
        );

        const flattenedData = filteredData.map((item, index) => {
          const price = parseInt(item.details.price);
          const hikedprice = parseInt(item.details.hikedprice);
          const commision = parseInt(item.details.commision);
          const gstPrice = parseInt(item.details.gstPrice);
          const paymentgateWayCharges = parseInt(
            item.details.paymentgateWayCharges
          );
          const amountToPay =
            hikedprice - price - (commision + gstPrice + paymentgateWayCharges);

          return {
            id: index + 1,
            driverName: item.details.driverName,
            driverMobile: item.details.driverMobile,
            amountToPay,
            hikedprice,
            dueAmount: commision + gstPrice + paymentgateWayCharges,
            bankAccountNumber: item.details.bankAccountNumber,
            bankaccountholdername: item.details.bankaccountholdername,
            ifscCode: item.details.ifscCode,
            BookingDate: item.driverHistory.date,
            bookingId: item.driverHistory.bookingId,
            driverId: item.driverHistory.driverId,
            isSettlementDone: item.details.isSettlementDone,
          };
        });
        setData(flattenedData.reverse());
      })
      .catch((error) => {
        console.error("Error fetching promotion history data:", error);
      });
  }, [done]);

  const handleSettle = (bookingId) => {
    const confirmation = window.confirm(
      "Are you sure you want to settle this promotion ride?"
    );

    if (confirmation) {
      const token = localStorage.getItem("token");
      axios
        .put(`https://api.holocabs.in/driverConfirm/do-settlement/${bookingId}`, {}, {
          headers: {
            Authorization: token
          }
        })
        .then((response) => {
          if (response.data.success) {
            toast.success("Promotion ride settled successfully");

            axios
              .put(`https://api.holocabs.in/driverConfirm/do-dueamount0-single/${bookingId}`, {}, {
                headers: {
                  Authorization: token
                }
              })
              .then((response) => {
                if (response.data.success) {
                  // Handle success, e.g., show a success message
                  toast.success("Due Amount set to 0");

                  // Optionally, update the data in state or reload the list
                  setDone(true);
                } else {
                  // Handle failure, e.g., show an error message
                  console.error("Failed to set due amount to 0");
                }
              });
          } else {
            console.error("Failed to settle promotion ride");
          }
        })
        .catch((error) => {
          console.error("Error settling promotion ride:", error);
        });
    }
  };

  const columns = [
    { field: "BookingDate", headerName: "Booking Date", width: 200 },
    { field: "driverName", headerName: "Driver Name", width: 200 },
    { field: "driverMobile", headerName: "Driver Number", width: 150 },
    {
      field: "amountToPay",
      headerName: "Amount to Pay",
      width: 150,
    },
    {
      field: "hikedprice",
      headerName: "HP (HIKED PRICE)",
      width: 150,
    },
    {
      field: "dueAmount",
      headerName: "Due Amount",
      width: 150,
    },
    {
      field: "bankAccountNumber",
      headerName: "Bank Account Number",
      width: 200,
    },
    { field: "ifscCode", headerName: "IFSC Code", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <button onClick={() => handleSettle(params.row.bookingId)}>
          Settle
        </button>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="promotion-settlement">
        <Typography variant="h6">Promotion Settlement</Typography>
        <DataGrid
          className="datagrid"
          rows={data}
          columns={columns}
          getRowId={(row) => row.id}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      </div>
    </>
  );
};

export default PromotionSettlement;
