import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import { saveAs } from "file-saver";

const SettlementC = () => {
  const [data, setData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);

  // Make a GET request to fetch ride history data
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://api.holocabs.in/driverConfirm/ride-history", {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const filteredData = response.data.filter(
          (item) => item.driverHistory.paymentCompleted
        );
        // console.log(filteredData);

        // Flatten the response data and add unique IDs
        const flattenedData = filteredData.map((item, index) => {
          const commision = parseInt(item.details.commision);
          const gstPrice = parseInt(item.details.gstPrice);
          const paymentgateWayCharges = parseInt(
            item.details.paymentgateWayCharges
          );
          const price = parseInt(item.details.price);
          const isDueExist =
            item.driverHistory.paymentMethod === "C" &&
            !item.driverHistory.isDueExist;

          return {
            id: index + 1,
            // driverId: item.details.driverId,
            driverName: item.details.driverName,
            driverMobile: item.details.driverMobile,
            amountToPay: price - (commision + gstPrice + paymentgateWayCharges),
            dueAmount: isDueExist
              ? commision + gstPrice + paymentgateWayCharges
              : 0,
            bankAccountNumber: item.details.bankAccountNumber,
            bankaccountholdername: item.details.bankaccountholdername,
            ifscCode: item.details.ifscCode,
            isSettlementDone: item.details.isSettlementDone,
            BookingDate: item.driverHistory.date,
            bookingId: item.driverHistory.bookingId,
            driverId: item.driverHistory.driverId,
            paymentMethod: item.driverHistory.paymentMethod,
          };
        });
        setData(flattenedData.reverse());

        // Create summary data based on driverMobile, excluding rows with isSettlementDone
        const driverMobileSummary = {};
        flattenedData.forEach((item) => {
          if (!item.isSettlementDone) {
            if (driverMobileSummary[item.driverMobile]) {
              if (item.paymentMethod !== "C") {
                driverMobileSummary[item.driverMobile].amountToPay +=
                  item.amountToPay;
              }
              driverMobileSummary[item.driverMobile].dueAmount +=
                item.dueAmount;
            } else {
              driverMobileSummary[item.driverMobile] = {
                driverId: item.driverId,
                driverMobile: item.driverMobile,
                bankaccountholdername: item.bankaccountholdername,
                bookingId: item.bookingId,
                bankAccountNumber: item.bankAccountNumber,
                ifscCode: item.ifscCode,
                dueAmount: item.dueAmount,
                amountToPay: item.paymentMethod !== "C" ? item.amountToPay : 0,
              };
            }
          }
        });

        // Convert the summary data object into an array
        const summaryArray = Object.values(driverMobileSummary);
        // console.log(summaryArray);

        setSummaryData(summaryArray);
      })
      .catch((error) => {
        console.error("Error fetching ride history data:", error);
      });
  }, []);

  const convertGMTtoIST = (gmtDate) => {
    const date = new Date(gmtDate);
    const istDate = new Date(
      date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDate.toLocaleString();
  };

  const columns = [
    {
      field: "BookingDate",
      headerName: "Booking Date",
      width: 200,
      valueGetter: (params) => convertGMTtoIST(params.row.BookingDate),
    },
    { field: "driverName", headerName: "Driver Name", width: 200 },
    { field: "driverMobile", headerName: "Driver Number", width: 150 },
    {
      field: "amountToPay",
      headerName: "Amount to Pay",
      width: 150,
    },
    {
      field: "dueAmount",
      headerName: "Due Amount",
      width: 150,
    },
    {
      field: "bankAccountNumber",
      headerName: "Bank Account Number",
      width: 200,
    },
    { field: "ifscCode", headerName: "IFSC Code", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {params.row.isSettlementDone ? (
            <CheckCircleIcon sx={{ color: "green" }} />
          ) : (
            <div>
              {params.row.dueAmount === 0 ? (
                <button
                  onClick={() => handleSettle(params.row.bookingId)}
                  disabled={params.row.isSettlementDone}
                  style={{ marginRight: "8px" }} // Add margin to the right of the "Settle" button
                >
                  Settle
                </button>
              ) : null}
              <button
                onClick={() => handleZeroDueAmount(params.row.bookingId)}
                disabled={params.row.isSettlementDone}
                style={{ marginLeft: "8px" }} // Add margin to the left of the "No Due" button
              >
                No Due
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];
  const summaryColumns = [
    { field: "driverMobile", headerName: "Driver Number", width: 150 },
    {
      field: "bankaccountholdername",
      headerName: "Bank Account Holder Name",
      width: 150,
    },
    {
      field: "bankAccountNumber",
      headerName: "Bank Account Number",
      width: 150,
    },
    { field: "ifscCode", headerName: "IFSC CODE", width: 150 },
    { field: "amountToPay", headerName: "Total Amount to Pay", width: 200 },
    { field: "dueAmount", headerName: "Total Due Amount", width: 200 },
  ];

  const handleSettle = (bookingId) => {
    const confirmation = window.confirm(
      "Are you sure you want to settle this booking?"
    );

    if (confirmation) {
      // User confirmed the action
      const token = localStorage.getItem("token");
      axios
        .put(
          `https://api.holocabs.in/driverConfirm/do-settlement/${bookingId}`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            // Handle success, e.g., show a success message
            console.log("Settlement is done");
            // Optionally, update the data in state or reload the list
          } else {
            // Handle failure, e.g., show an error message
            console.error("Failed to settle");
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error settling:", error);
        });
    }
  };

  const handleZeroDueAmount = (bookingId) => {
    const confirmation = window.confirm(
      "Are you sure you want to set due amount to 0 for this driver?"
    );

    if (confirmation) {
      // User confirmed the action
      const token = localStorage.getItem("token");
      axios
        .put(`https://api.holocabs.in/driverConfirm/do-dueamount0-single/${bookingId}`, {}, {
          headers: {
            Authorization: token,
          }
        })
        .then((response) => {
          if (response.data.success) {
            // Handle success, e.g., show a success message
            console.log("Due Amount set to 0");
            // Optionally, update the data in state or reload the list
          } else {
            // Handle failure, e.g., show an error message
            console.error("Failed to set due amount to 0");
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error setting due amount to 0:", error);
        });
    }
  };

  const handleDownloadPayableData = () => {
    // Filter summaryData to include only those with Total Amount to Pay greater than 0
    const payableData = summaryData.filter((item) => item.amountToPay > 0);

    // Create a CSV content string
    const csvContent =
      "Driver Number,Bank Account Holder Name,Bank Account Number,IFSC CODE,Total Amount to Pay,Total Due Amount\n" +
      payableData
        .map(
          (item) =>
            `${item.driverMobile},${item.bankaccountholdername},${item.bankAccountNumber},${item.ifscCode},${item.amountToPay},${item.dueAmount}`
        )
        .join("\n");

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // Use file-saver to save the Blob as a file
    saveAs(blob, "alldata.csv");
  };
  const handleDownloadReceivableData = () => {
    // Filter summaryData to include only those with Total Amount to Pay greater than 0
    const payableData = summaryData.filter((item) => item.dueAmount > 0);

    // Create a CSV content string
    const csvContent =
      "Driver Number,Bank Account Holder Name,Bank Account Number,IFSC CODE,Total Amount to Pay,Total Due Amount\n" +
      payableData
        .map(
          (item) =>
            `${item.driverMobile},${item.bankaccountholdername},${item.bankAccountNumber},${item.ifscCode},${item.amountToPay},${item.dueAmount}`
        )
        .join("\n");

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // Use file-saver to save the Blob as a file
    saveAs(blob, "alldata.csv");
  };

  const handleDoDueAmountOfMultipleDrivers = () => {
    // Filter summaryData to include only those with Total Amount to Pay greater than 0
    const payableData = summaryData.filter((item) => item.amountToPay > 0);

    // Extracting driverIds from payableData
    const driverIds = payableData.map((item) => item.driverId);
    const token = localStorage.getItem("token");

    // Sending request to your /do-dueamount0 endpoint with driverIds
    axios
      .put("https://api.holocabs.in/driverConfirm/do-dueamount0-mul", { driverIds }, {
        headers: {
          Authorization: token
        }
      })
      .then((response) => {
        console.log(response.data);
        // Handle response if needed
      })
      .catch((error) => {
        console.error(error);
        // Handle error if needed
      });
  };
  // console.log("VALUE OF STRUCT",columns);
  return (
    <div className="settlementc">
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
      <div className="summary-heading">
        <Typography variant="h6">Summary Info</Typography>
        <button onClick={handleDownloadPayableData}>
          Download Payable Data
        </button>
        <button
          onClick={handleDownloadReceivableData}
          style={{ marginTop: 10 }}
        >
          Download Receivable Data
        </button>
        <button
          onClick={handleDoDueAmountOfMultipleDrivers}
          style={{ marginTop: 10 }}
        >
          NO DUE
        </button>
      </div>

      <DataGrid
        className="summary-grid"
        rows={summaryData}
        columns={summaryColumns}
        getRowId={(row) => row.driverMobile}
      />
    </div>
  );
};

export default SettlementC;
