import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Snackbar,
} from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";

const CancelledRides = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Fetch data from the server using Axios
    const token = localStorage.getItem("token");
    axios
      .get("https://api.holocabs.in/booked/get-cancelled-rides", {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }, []);

  // Calculate the index of the last item to be displayed on the current page
  const lastIndex = page * rowsPerPage;
  // Calculate the index of the first item to be displayed on the current page
  const firstIndex = lastIndex - rowsPerPage;
  // Get the current page of data to display
  const currentPageData = data.slice(firstIndex, lastIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const convertISOToIST = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const handleCopy = (label) => {
    setOpen(true);
    navigator.clipboard.writeText(label);
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ width: "12%" }}>
        <Sidebar />
      </div>
      <div style={{ flex: 1, overflow: "auto" }}>
        <h1 style={{ padding: 10 }}>Cancelled Rides </h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "red" }}>
              <TableRow>
                <TableCell style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Customer Name
                </TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Pickup Location</TableCell>
                <TableCell>Drop Location</TableCell>
                <TableCell>Distance</TableCell>
                <TableCell>Type of Car</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Pick Lat & Lng</TableCell>
                <TableCell>Destination Lat & Lng</TableCell>
                <TableCell>ETD</TableCell>
                <TableCell>Commision</TableCell>
                <TableCell>GST Price</TableCell>
                <TableCell>Payment Gateway Charges</TableCell>
                <TableCell>Night Charges</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.userName}</TableCell>
                  <TableCell>{item.mobile}</TableCell>
                  <TableCell>{item.plocation}</TableCell>
                  <TableCell>{item.dlocation}</TableCell>
                  <TableCell>{item.distance}</TableCell>
                  <TableCell>{item.typeOfCar}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell
                    onClick={() =>
                      handleCopy(`${item.platitude},${item.plongitude}`)
                    }
                    style={{ cursor: "pointer", color: 'blue' }}
                  >
                    {item.platitude},{item.plongitude}
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleCopy(`${item.dlatitude},${item.dlongitude}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {item.dlatitude},{item.dlongitude}
                  </TableCell>
                  <TableCell>{item.timebyGoogle}</TableCell>
                  <TableCell>{item.commision}</TableCell>
                  <TableCell>{item.gstPrice}</TableCell>
                  <TableCell>{item.paymentgateWayCharges}</TableCell>
                  <TableCell>{item.nightCharges}</TableCell>
                  <TableCell>{convertISOToIST(item.date)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </div>
  );
};

export default CancelledRides;
