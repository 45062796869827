import React from 'react'
import "./settlementd.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from '../../components/navbar/Navbar'
import SettlementC from '../../components/settlement/settlementc'
const settlementd = () => {
  return (
    <div className="list">
     <Sidebar/>
     <div className="listContainer">
     <Navbar/>
     <SettlementC/>
     </div>
    </div>
  )
}

export default settlementd