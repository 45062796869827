import React, { useEffect, useState } from "react";
import axios from "axios";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
const Single = () => {
  const navigation = useNavigate();

  const [user, setUser] = useState(null);
  const userId = useParams().userId;
  
  const url = window.location.href;
  const parts = url.split("/");
  const desiredValue = parts[parts.length - 2];
  const driverId = parts[parts.length - 1];

//  console.log(desiredValue);
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://api.holocabs.in/passenger/getuser/${userId}`,
          {
            headers: {
              Authorization: token,
            }
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    const fetchDriver = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://api.holocabs.in/driver/getdriverdetails/${driverId}`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

if(desiredValue == 'drivers'){
fetchDriver()
}

else{
  fetchUser();
}

  }, []);
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
{         desiredValue== 'drivers'?   <div className="editButton"><Link to={`/editdrivers/${driverId}`}>Edit</Link></div> :''

  }
  
            <h1 className="title">Information</h1>
            {user && (
              <div className="item">
                <img
                  src={user.profile_pic}
                  alt=""
                  className="itemImg"
                  onError={(e) => {
                    console.error(e);
                  }}
                />
                <div className="details">
                  <h1 className="itemTitle">{ desiredValue=='drivers' ? user.driverName : user.name}</h1>
                  
                  <div className="detailItem">
                    <span className="itemKey">Phone:</span>
                    <span className="itemValue">{desiredValue=='drivers' ? user.driverMobile : user.mobile}</span>
                  </div>
                  
                  {desiredValue == 'drivers' ?  <div className="detailItem">
                    <span className="itemKey">Adhar Number:</span>
                    <span className="itemValue">{ user.adharNo}</span>

                  </div> :'' }
                  
                  {desiredValue == 'drivers' ?  <div className="detailItem">
                    <span className="itemKey">Gender:</span>
                    <span className="itemValue">{ user.driverGender}</span>

                  </div> :'' }

                  

                  {desiredValue == 'drivers' ?  <div className="detailItem">
                    <span className="itemKey">Vehicle Number:</span>
                    <span className="itemValue">{ user.RCNumber}</span>

                  </div> :'' }
                  {desiredValue == 'drivers' ?  <div className="detailItem">
                    <span className="itemKey">DL Number:</span>
                    <span className="itemValue">{ user.dlNumber}</span>

                  </div> :'' }
                  {desiredValue == 'drivers' ?  <div className="detailItem">
                    <span className="itemKey">Vehicle Type:</span>
                    <span className="itemValue">{ user.vehicleType}</span>
                    
                  </div> :'' }
                  {desiredValue == 'drivers' ?  <div className="detailItem">
                    <span className="itemKey">Total Income:</span>
                    <span className="itemValue">{ user.totalIncome}</span>
                    
                  </div> :'' }
                  {desiredValue == 'drivers' ?  <div className="detailItem">
                    <span className="itemKey">UPI ID:</span>
                    <span className="itemValue">{ user.upiId}</span>
                    
                  </div> :'' }
                 
                  <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    <span className="itemValue">{"INDIA"}</span>
                  </div>
                  {desiredValue == 'drivers' ?  <div className="detailItem">
                   <button onClick={()=> navigation(`/map/${user.location.coordinates[0]}/${user.location.coordinates[1]}`)}>See Location</button>
                  </div> :'' }
                </div>
              </div>
            )}
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="User Spending (Last 6 Months)" />
          </div>
        </div>
        <div className="bottom">
          <h1 className="title">Last Transactions</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default Single;
