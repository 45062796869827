import React, { useState } from 'react';
import axios from 'axios';
import './NewUser.css'; // Import your CSS file for styling
import Navbar from './sidebar/Sidebar';
import { Link } from 'react-router-dom';

function NewUser() {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!name || !mobile) {
      alert('Please enter both name and mobile number.');
      return;
    }

    setLoading(true);

    try {
        if(mobile.length===10){
            const response = await axios.post('https://api.holocabs.in/usersave', {
                name,
                mobile
              });
        
              alert(response.data.message);
        
              // Reset form
              setName('');
              setMobile('');
        }
        alert("Enter correct number")
     
    } catch (error) {
      console.error('Error adding user:', error);
    }
    setLoading(false);
  };

  return (
    <>
             <div className="custom-navbar" style={{width:"100vw",background:"#fff",boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)",height:"60px",display:"flex",justifyContent:"space-around",alignItems:"center",paddingLeft:20,paddingRight:20,overflow:'hidden'}}>
      <div><h2 style={{color:'#000'}}><Link to={'/'}  style={{textDecoration:'none'}}>Holycabs</Link></h2></div>
      <div>
      <Link style={{textDecoration:'none',marginLeft:20,marginRight:20,padding:5,borderRadius:5,border:"1px solid #edca11",color:"#000",width:150}} to="/">Home</Link>
        <Link style={{textDecoration:'none',marginLeft:20,marginRight:20,padding:5,borderRadius:5,border:"1px solid #edca11",color:"#000",width:150}} to="/drivers">Drivers</Link>
        <Link style={{textDecoration:'none',marginLeft:20,marginRight:20,padding:5,borderRadius:5,border:"1px solid #edca11",color:"#000",width:150}} to="/users">Users</Link>
        <Link style={{textDecoration:'none',marginLeft:20,marginRight:20,padding:5,borderRadius:5,border:"1px solid #edca11",color:"#000",width:150}} to="/ridehistory">Ride History</Link>
      </div>
      </div>


    <div className="new-user-container">
      <h2>Add New User</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Mobile:</label>
          <input
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="form-group">
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Adding...' : 'Add User'}
          </button>
        </div>
      </form>
    </div>
    </>

  );
}

export default NewUser;
