import React, { useEffect, useState } from "react";
import "./RideHistory.css";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import axios from "axios";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  TextField,
  Button,
} from "@mui/material";

const RideHistory = () => {
  const [rideData, setRideData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredRideData, setFilteredRideData] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          "https://api.holocabs.in/driverConfirm/ride-history",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setRideData(response.data.reverse());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = () => {
    const filteredData = rideData.filter((ride) => {
      const details = ride.details;
      const last10DigitsOfId = ride.driverHistory._id.slice(-10);
      const searchTerms = [
        details.userName,
        last10DigitsOfId.toString(),
        details.driverName,
        details.vehicleType,
        details.driverMobile,
        details.driverId,
        details.getOriginalId,
      ];

      return searchTerms.some(
        (term) => term && term.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    setFilteredRideData(filteredData);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const convertToIST = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const convertISOToIST = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const indexOfLastRide = page * rowsPerPage;
  const indexOfFirstRide = indexOfLastRide - rowsPerPage;
  const currentRides = searchText === "" ? rideData : filteredRideData;
  const currentRidesOnPage = currentRides.slice(
    indexOfFirstRide,
    indexOfLastRide
  );

  return (
    <div className="list">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="listContainer">
        <div className="navbar">
          <Navbar />
        </div>
        <h1 style={{ padding: 10 }}>Ride Activities</h1>
        <div
          className="search"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            style={{
              marginLeft: 20,
              marginTop: 20,
              height: "40px",
              borderRadius: 5,
              paddingLeft: 5,
              outline: "none",
              width: 300,
              paddingBottom: 50,
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", marginTop: 20, marginLeft: 20 }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
        <div className="ridehistorybox">
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ backgroundColor: "orange" }}>
                <TableRow>
                  <TableCell>Ride ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Driver Accepted At</TableCell>
                  <TableCell>Ride Completed At</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>Driver Name</TableCell>
                  <TableCell>Vehicle Type</TableCell>
                  <TableCell>Driver ID</TableCell>
                  <TableCell>Driver Mobile</TableCell>
                  <TableCell>Fare Charge</TableCell>
                  <TableCell>Live Location</TableCell>
                  <TableCell>Pickup Location</TableCell>
                  <TableCell>Destination Location</TableCell>
                  <TableCell>Distance</TableCell>
                  <TableCell>Ride Status</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>OTP Status</TableCell>
                  <TableCell>RIDER OTP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRidesOnPage.map((ride, index) => (
                  <TableRow key={index}>
                    <TableCell>{ride.driverHistory._id.slice(-10)}</TableCell>
                    <TableCell>
                      {convertISOToIST(ride.driverHistory.date)}
                    </TableCell>
                    <TableCell>
                      {convertToIST(ride.driverHistory.driveracceptedat)}
                    </TableCell>
                    <TableCell>
                      {convertToIST(ride.driverHistory.ridecompletedat)}
                    </TableCell>
                    <TableCell>{ride.details.userName}</TableCell>
                    <TableCell>
                      <a href={`/users/${ride.details.getOriginalId}`}>
                        {ride.details.getOriginalId}
                      </a>
                    </TableCell>
                    <TableCell>{ride.details.driverName}</TableCell>
                    <TableCell>{ride.details.vehicleType}</TableCell>
                    <TableCell>
                      <a href={`/drivers/${ride.driverHistory.driverId}`}>
                        {ride.driverHistory.driverId}
                      </a>
                    </TableCell>
                    <TableCell>{`${ride.details.driverMobile}`}</TableCell>
                    <TableCell>{`₹${ride.details.price}`}</TableCell>
                    <TableCell>
                      {ride.driverHistory.rideCompleted ? (
                        "NOT AVAILABLE"
                      ) : (
                        <a
                          href={`https://main.holocabs.com/Singlemaps/${ride.details.liveLocationKey}`}
                        >
                          LINK
                        </a>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "5px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {ride.details.plocation}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "5px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {ride.details.dlocation}
                    </TableCell>
                    <TableCell>{`${ride.details.distance} KM`}</TableCell>
                    <TableCell>
                      {ride.driverHistory.rideCompleted
                        ? "Completed"
                        : "Not Completed"}
                    </TableCell>
                    <TableCell>
                      {ride.driverHistory.paymentCompleted
                        ? "Completed"
                        : "Not Completed"}
                    </TableCell>
                    <TableCell>
                      {ride.details.destinationStart ||
                      ride.driverHistory.paymentCompleted
                        ? "VERIFIED"
                        : "NOT VERIFIED"}
                    </TableCell>
                    <TableCell>{ride.details.otpforValid}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(currentRides.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RideHistory;
