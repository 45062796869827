import React from "react";
import "./Notification.scss";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import SendNotification from "../../pages/sendnotification/SendNotification";
const Notification = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <SendNotification />
      </div>
    </div>
  );
};

export default Notification;
