import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const TshirtDetails = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTshirtDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.holocabs.in/driver/api/tshirtdetails",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setRows(response.data);
        setLoading(false);
        toast.success("T-shirt details fetched successfully!");
      } catch (error) {
        setLoading(false);
        toast.error("Error fetching T-shirt details.");
        console.error("Error fetching T-shirt details:", error);
      }
    };

    fetchTshirtDetails();
  }, []);

  const columns = [
    { field: "driverId", headerName: "Driver ID", width: 150 },
    { field: "TshirtSize", headerName: "T-shirt Size", width: 130 },
    { field: "Name", headerName: "Name", width: 200 },
    { field: "PhoneNumber", headerName: "Phone Number", width: 150 },
    { field: "Address", headerName: "Address", width: 200 },
    { field: "Landmark", headerName: "Landmark", width: 200 },
    { field: "Pincode", headerName: "Pincode", width: 120 },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <ToastContainer />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={rows.map((row, index) => ({ ...row, id: index + 1 }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
        />
      )}
    </div>
  );
};

export default TshirtDetails;
