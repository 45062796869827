import React from 'react'
import "./rewards.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from '../../components/navbar/Navbar'
import Promo from '../../components/promotional/PromoC'
const rewards = () => {
  return (
    <div className="list">
     <Sidebar/>
     <div className="listContainer">
     <Navbar/>
     <Promo/>
     </div>
    </div>
  )
}

export default rewards