import React, { useState } from "react";
import "./Login.css"; // Import the CSS file for styling
// import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navig = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your login logic here

    try {
      const response = await axios.post(
        "https://api.holocabs.in/auth/adminlogin",
        {
          username: username,
          password: password,
        }
      );

      if (response.data.success === true) {
        // Save username and password to cookies
        alert(response.data.message);
        localStorage.setItem("token", response.data.token);
        navig("/");
        window.location.reload();
        // Do something after successful login, like redirecting
        // For example: window.location.href = '/dashboard';
      } else {
        alert("Invalid Credentials!");
      }
    } catch (error) {
      alert("Invalid Credentials!");

      // console.error('Error during login:', error);
    }
    // console.log('Login credentials:', { username, password });
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <button onClick={handleSubmit} type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
