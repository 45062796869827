import React from 'react'
import "./ActivationList.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from '../../components/navbar/Navbar'
import ActivationModule from '../../components/activation/ActivationModule'
const ActivationList = () => {
  return (
    <div className="list">
     <Sidebar/>
     <div className="listContainer">
     <Navbar/>
     <ActivationModule/>
     </div>
    </div>
  )
}

export default ActivationList