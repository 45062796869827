import React from 'react'
import "./PassengerSOSd.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from '../../components/navbar/Navbar'
import PassengerSOS from '../../components/passengerSOS/PassengerSOSC'
const PassengerSOSd = () => {
  return (
    <div className="list">
     <Sidebar/>
     <div className="listContainer">
     <Navbar/>
     <PassengerSOS/>
     </div>
    </div>
  )
}

export default PassengerSOSd