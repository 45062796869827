import React, { useEffect, useState, useRef, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import RegularTaxiImage from "./assets/taxi.png";
import PrimeTaxiImage from "./assets/SedanICON.png";
import SuvTaxiImage from "./assets/SUVICON.png";
import AutoImage from "./assets/AutoIcon.png";

import io from "socket.io-client";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";

// Function to animate the map based on latitude and longitude
function AnimateMap({ lat, lng }) {
  const map = useMap();
  useEffect(() => {
    if (lat && lng) {
      map.flyTo([lat, lng], 18, {
        animate: true,
        duration: 2,
      });
    }
  }, [lat, lng, map]);
  return null;
}

function MultipleMarkers() {
  const [driverData, setDriverData] = useState([]);
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [filteredDriverData, setFilteredDriverData] = useState([]);

  const [lnglat, Setlnglat] = useState("");

  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = io("https://api.holocabs.in", {
      path: "/socket.io/",
    });
    socketRef.current.emit("givemeallinitialdriverdata");

    socketRef.current.on("getalldriver", (drivers) => {
      console.log(drivers);
      setDriverData(drivers);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    const extractedData = driverData.map((item) => ({
      lat: item.location.coordinates[1],
      long: item.location.coordinates[0],
      _id: item._id,
      name: item.driverName,
      isOnline: item.isAvailable,
      vehicleType: item.vehicleType,
      driverMobile: item.driverMobile,
    }));

    const filteredDrivers = extractedData.filter((driver) => {
      return (
        (!showActiveOnly || driver.isOnline) &&
        (!selectedVehicleType || driver.vehicleType === selectedVehicleType)
      );
    });

    setFilteredDriverData(filteredDrivers);
  }, [driverData, showActiveOnly, selectedVehicleType]);

  const handleShowActiveDrivers = () => {
    setShowActiveOnly(!showActiveOnly);
  };

  const handleVehicleTypeChange = (event) => {
    setSelectedVehicleType(event.target.value);
  };

  // const handleSearchLatLng = () => {
  //   if (searchLat && searchLng) {
  //     setSearchLat(parseFloat(searchLat));
  //     setSearchLng(parseFloat(searchLng));
  //   }
  // };

  const Regular = useMemo(() => {
    return new Icon({
      iconUrl: RegularTaxiImage,
      iconSize: [32, 32],
    });
  }, []);

  const PrimeSedan = useMemo(() => {
    return new Icon({
      iconUrl: PrimeTaxiImage,
      iconSize: [32, 32],
    });
  }, []);

  const SUVPremium = useMemo(() => {
    return new Icon({
      iconUrl: SuvTaxiImage,
      iconSize: [32, 32],
    });
  }, []);

  const Autorickshaw = useMemo(() => {
    return new Icon({
      iconUrl: AutoImage,
      iconSize: [32, 32],
    });
  }, []);

  return (
    <div
      style={{
        width: "80vw",
        height: "80vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MapContainer
        center={[20.4676, 85.88]}
        zoom={12}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          attribution="Google Maps"
          url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
        />
        <AnimateMap lat={lnglat.split(",")[0]} lng={lnglat.split(",")[1]} />

        {filteredDriverData.map((driver, index) => (
          <Marker
            position={[driver.lat, driver.long]}
            icon={
              driver.vehicleType === "Regular"
                ? Regular
                : driver.vehicleType === "Prime/Sedan"
                ? PrimeSedan
                : driver.vehicleType === "SUV/Premium"
                ? SUVPremium
                : driver.vehicleType === "Auto rickshaw"
                ? Autorickshaw
                : Regular
            }
          >
            <Popup>
              {driver.name} ({driver.vehicleType}) - ({driver.driverMobile})
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      {/* Control Section */}
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ backgroundColor: "#6dbe75", marginRight: "10px" }}
          onClick={handleShowActiveDrivers}
        >
          <h2 style={{ color: "white" }}>
            {showActiveOnly ? "SHOW ALL DRIVERS" : "SHOW ACTIVE DRIVERS"}
          </h2>
        </Button>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Vehicle Type</InputLabel>
          <Select
            value={selectedVehicleType}
            onChange={handleVehicleTypeChange}
            label="Vehicle Type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="Regular">Regular</MenuItem>
            <MenuItem value="Prime/Sedan">Prime/Sedan</MenuItem>
            <MenuItem value="SUV/Premium">SUV/Premium</MenuItem>
            <MenuItem value="Auto rickshaw">Holo Auto</MenuItem>
          </Select>
        </FormControl>
        {/* Search Box */}
        <TextField
          label="Latitude & Longitude"
          variant="outlined"
          value={lnglat}
          onChange={(e) => Setlnglat(e.target.value)}
          style={{ marginLeft: "10px" }}
        />

        {/* <Button
          style={{
            backgroundColor: "#3f51b5",
            color: "white",
            marginLeft: "10px",
          }}
          onClick={handleSearchLatLng}
        >
          Search
        </Button> */}
      </div>

      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <h4>Documentation</h4>
        <div>
          <img src={RegularTaxiImage} alt="Regular" width="32" height="32" />{" "}
          Regular Taxi
        </div>
        <div>
          <img src={PrimeTaxiImage} alt="Prime" width="32" height="32" />{" "}
          Prime/Sedan Taxi
        </div>
        <div>
          <img src={SuvTaxiImage} alt="SUV" width="32" height="32" />{" "}
          SUV/Premium Taxi
        </div>
        <div>
          <img src={AutoImage} alt="Auto" width="32" height="32" /> Auto
          rickshaw
        </div>
      </div>
    </div>
  );
}

export default MultipleMarkers;
