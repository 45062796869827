import React from 'react'
import "./RenewalList.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from '../../components/navbar/Navbar'
import DocsStats from '../../components/renewal/DocsStats'
const RenewalList = () => {
  return (
    <div className="list">
     <Sidebar/>
     <div className="listContainer">
     <Navbar/>
     <DocsStats/>
     </div>
    </div>
  )
}

export default RenewalList