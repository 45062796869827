import React from 'react'
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from '../../components/navbar/Navbar'
import MultipleMarkers from '../../components/MultipleMarkers';
const MapsPage = () => {
  return (
    <div className="list">
     <Sidebar/>
     <div className="listContainer">
     <Navbar/>
     <div style={{width:"80%"}}>
    
    <MultipleMarkers/>
</div>
     </div>
    </div>
  )
}

export default MapsPage