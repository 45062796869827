import React from 'react'
import "./DriverSOSd.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from '../../components/navbar/Navbar'
import DriverSOS from '../../components/driverSOS/DriverSOSC'
const DriverSOSd = () => {
  return (
    <div className="list">
     <Sidebar/>
     <div className="listContainer">
     <Navbar/>
     <DriverSOS/>
     </div>
    </div>
  )
}

export default DriverSOSd