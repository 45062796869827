import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import TaxiImage from '../assets/taxi.png';
import { useParams } from 'react-router-dom';

function Map() {
  const [taxiPosition, setTaxiPosition] = useState([0, 0]); // Default taxi position with 0 latitude and longitude

  const { lat, long } = useParams();

  useEffect(() => {
    // Use parseFloat to convert strings to numbers
    const newLatitude = parseFloat(lat);
    const newLongitude = parseFloat(long);
// console.log(newLatitude);
    // Check if the conversion resulted in valid numbers
    if (!isNaN(newLatitude) && !isNaN(newLongitude)) {
      setTaxiPosition([newLatitude, newLongitude]);
    }
  }, [lat, long]);

  const taxiIcon = new Icon({
    iconUrl: TaxiImage,
    iconSize: [32, 32],
  });

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <MapContainer center={[lat, long]} zoom={12} style={{ width: '100%', height: '100%' }}>
      <TileLayer
          attribution="Google Maps"
          url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
        />

        <Marker position={taxiPosition} icon={taxiIcon}>
          <Popup>
            This is the taxi location
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default Map;





// ************************************GOOGLE MAP INTEGRATION CODE ************************************//
// import React from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// const Map = () => {
//   // Replace 'YOUR_GOOGLE_MAPS_API_KEY' with your actual API key
//   const apiKey = "";

//   // Initial coordinates to center the map
//   const initialCoordinates = {
//     lat: 37.7749, // Replace with the initial latitude
//     lng: -122.4194 // Replace with the initial longitude
//   };

//   // Coordinates for the marker
//   const markerCoordinates = {
//     lat: 37.7749, // Replace with the marker's latitude
//     lng: -122.4194 // Replace with the marker's longitude
//   };

//   return (
//     <LoadScript googleMapsApiKey={apiKey}>
//       <GoogleMap
//         mapContainerStyle={{
//           width: "100%",
//           height: "400px"
//         }}
//         center={initialCoordinates}
//         zoom={10}
//       >
//         <Marker position={markerCoordinates} />
//       </GoogleMap>
//     </LoadScript>
//   );
// };

// export default Map;
