import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import TaxiImage from "./assets/taxi.png";
import { useParams } from "react-router-dom";
import axios from "axios";
// import io from "socket.io-client";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Singlemaps() {
  const { Livelocationkey } = useParams();
  const [driverData, setDriverData] = useState(null);
  const [driverID, setDriverID] = useState(null); // Fix the state variable name
  const [expired, setExpired] = useState(false);

  const fetchDriverId = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://api.holocabs.in/driver/finddriveridfromlivelocationkey/${Livelocationkey}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setDriverID(response.data.driverID); // Use response.data.driverID to set the state
      setExpired(false); // Reset expired flag on successful fetch
    } catch (error) {
      console.error("Error fetching driver ID:", error);
      setExpired(true); // Set expired flag if there's an error
    }
  };

  // const fetchDriverData = async () => {
  //   try {
  //     if (driverID) {
  //       // Only make the request if driverID is available
  //       const response = await axios.get(
  //         `https://api.holocabs.in/getdriverdetails/${driverID}`
  //       );
  //       setDriverData(response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching driver data:", error);
  //   }
  // };

  useEffect(() => {
    fetchDriverId();
  }, [Livelocationkey]);

  useEffect(() => {
    if (driverID != null) {
      // Create a new EventSource instance
      const eventSource = new EventSource(
        `https://api.holocabs.in/driver/fetchdriverlocation?driverID=${driverID}`
      );

      // Event listener for incoming messages
      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        // console.log("DATA FROM SSE", newMessage);
        setDriverData(newMessage);
      };

      // Error handling
      eventSource.onerror = (error) => {
        console.error("EventSource error:", error);
        eventSource.close();
      };

      // Cleanup on component unmount
      return () => {
        eventSource.close();
      };
    }
  }, [driverID]);

  // useEffect(() => {
  //   if (driverID != null) {
  //     const socket = io("http://192.168.42.238:5000", {
  //       path: "/socket.io/",
  //     });

  //     socket.emit("getdriverdetails", { driverID });

  //     socket.on("updatedriverdetails", (updatedDriver) => {
  //       console.log("Received updated driver details:", updatedDriver);
  //       // Update the state with the new driver data
  //       setDriverData(updatedDriver);
  //     });

  //     // Cleanup the socket listener on component unmount
  //     return () => {
  //       socket.disconnect();
  //     };
  //   }
  // }, [driverID]);

  const taxiIcon = new Icon({
    iconUrl: TaxiImage,
    iconSize: [32, 32],
  });

  // const handleRefreshClick = () => {
  //   // Call the fetchDriverData function to refresh the data
  //   fetchDriverData();
  // };

  return (
    <div
      style={{
        width: "80vw",
        height: "80vh",
        marginLeft: "10vw",
        marginBottom: 50,
      }}
    >
      {expired ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <p style={{ fontSize: "24px", color: "red", marginBottom: "20px" }}>
            LINK IS EXPIRED
          </p>
          <i
            className="fa-solid fa-times"
            style={{ color: "red", fontSize: "48px" }}
          ></i>
        </div>
      ) : driverData && driverData.location ? (
        <MapContainer
          center={[
            driverData.location.coordinates[1],
            driverData.location.coordinates[0],
          ]}
          zoom={12}
          style={{ width: "100%", height: "100%" }}
        >
          <TileLayer
            attribution="Google Maps"
            url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
          />

          <Marker
            position={[
              driverData.location.coordinates[1],
              driverData.location.coordinates[0],
            ]}
            icon={taxiIcon}
          >
            <Popup>Taxi location - {driverData.driverName}</Popup>
          </Marker>
        </MapContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {driverData && driverData.location && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <a
            href={`https://www.google.com/maps?q=${driverData.location.coordinates[1]},${driverData.location.coordinates[0]}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              background: "#EDCA11",
              color: "black",
              textDecoration: "none",
              border: "1px solid #EDCA11",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            Open With Google Maps
          </a>
        </div>
      )}
    </div>
  );
}

export default Singlemaps;
