import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const SendNotification = () => {
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("ALL DRIVERS");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendNotification = async () => {
    if (!message.trim()) {
      toast.error("Please enter a message.");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        "https://api.holocabs.in/booked/send-notification",
        {
          message: message.trim(),
          category,
          imageUrl: imageUrl.trim(),
        },
        {
          headers: {
            Authorization: token
          }
        }
      );

      if (response.status === 200) {
        toast.success("Notification sent successfully.");
        setMessage("");
        setCategory("ALL DRIVERS");
        setImageUrl(""); // Reset the image URL
      } else {
        toast.error("Failed to send notification.");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("Failed to send notification.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: 3, marginTop: 4, width: "100%", margin: "auto" }}
    >
      <ToastContainer />
      <Typography variant="h5" align="center" gutterBottom>
        Send Notification
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={3}>
          {/* Message Input */}
          <Grid item xs={12}>
            <TextField
              label="Message"
              multiline
              rows={4}
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              variant="outlined"
              required
            />
          </Grid>
          {/* Category Select */}
          <Grid item xs={12}>
            <TextField
              select
              label="Select Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="ALL DRIVERS">ALL DRIVERS</MenuItem>
              <MenuItem value="ALL HOLO AUTO">ALL HOLO AUTO DRIVERS</MenuItem>
              <MenuItem value="ALL PASSENGERS">ALL PASSENGERS</MenuItem>
            </TextField>
          </Grid>
          {/* Image URL Input */}
          <Grid item xs={12}>
            <TextField
              label="Image URL"
              fullWidth
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendNotification}
              sx={{ padding: "10px 20px" }}
              disabled={loading}
            >
              {loading ? "Sending..." : "SEND"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SendNotification;
