import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "react-modal";
import { driverColumns, userColumns } from "../../datatablesource";

const Datatable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const navigate = useNavigate();

  // Get URL last part to differentiate between users and drivers
  const url = window.location.href;
  const parts = url.split("/");
  const desiredValue = parts[parts.length - 1];

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Set loading state to true before fetching data
    setLoading(true);

    const fetchData = async () => {
      try {
        const endpoint =
          desiredValue === "drivers"
            ? "https://api.holocabs.in/driver/getalldriver"
            : "https://api.holocabs.in/passenger/getalluser";

        const response = await axios.get(endpoint, {
          headers: {
            Authorization: token,
          },
        });

        const updatedData = response.data.map((row) => ({
          ...row,
          id: row._id,
        }));
        setData(updatedData.reverse());
        setLoading(false); // Data fetched, stop loading
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [desiredValue]);

  const handleDelete = (id) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    const token = localStorage.getItem("token");
    const deleteURL =
      desiredValue === "drivers"
        ? `https://api.holocabs.in/driver/deletedriveraccountid/${deleteItemId}`
        : `https://api.holocabs.in/passenger/deleteuser/${deleteItemId}`;

    try {
      await axios.delete(deleteURL, {
        headers: {
          Authorization: token,
        },
      });
      setData(data.filter((item) => item.id !== deleteItemId));
      setDeleteItemId(null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const cancelDelete = () => {
    setDeleteItemId(null);
    setShowDeleteModal(false);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div
          className="cellAction"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="viewButton"
            onClick={() => {
              navigate(
                desiredValue === "drivers"
                  ? `/drivers/${params.row.id}`
                  : `/users/${params.row.id}`
              );
            }}
            style={{
              backgroundColor: "#edca11",
              color: "black",
              padding: "4px",
              borderRadius: "4px",
              cursor: "pointer",
              marginRight: 20,
            }}
          >
            View
          </div>
          <div
            className="deleteButton"
            onClick={() => handleDelete(params.row.id)}
            style={{
              backgroundColor: "#edca11",
              color: "black",
              padding: "4px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Delete
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      {desiredValue !== "drivers" && (
        <div
          className="datatableTitle"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
        >
          Add new user :
          <Link
            to="/users/new"
            style={{
              textDecoration: "none",
              padding: 5,
              borderRadius: 5,
              background: "purple",
              color: "#fff",
              marginLeft: 5,
            }}
            className="link"
          >
            Add new
          </Link>
        </div>
      )}

      {loading ? (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          Loading data...
        </div>
      ) : error ? (
        <div style={{ color: "red", textAlign: "center", marginTop: 50 }}>
          {error}
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={data}
          columns={
            desiredValue === "drivers"
              ? driverColumns.concat(actionColumn)
              : userColumns.concat(actionColumn)
          }
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={cancelDelete}
        style={{
          content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "300px",
            height: "150px",
            margin: "auto",
          },
        }}
      >
        <h3>Confirm Delete</h3>
        <p>
          Are you sure you want to delete this{" "}
          {desiredValue === "drivers" ? "DRIVER" : "USER"}?
        </p>
        <div>
          <button
            onClick={confirmDelete}
            style={{
              backgroundColor: "#4CAF50",
              border: "none",
              color: "white",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            Delete
          </button>
          <button
            onClick={cancelDelete}
            style={{
              backgroundColor: "#2196F3",
              border: "none",
              color: "white",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Datatable;
