import React,{useState,useEffect} from "react";
import "./chart.scss"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from "axios";


const data=[
  {name: "January", Total: 1200},
  {name: "February", Total: 2100 },
  {name: "March", Total:800},
  {name: "April", Total:1600},
  {name: "May", Total:900},
  {name: "June", Total:1700},
];



const Chart = ({aspect,title}) => {
  const [rideData, setRideData] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://api.holocabs.in/driverConfirm/ride-history',{
        headers: {
          Authorization: token, 
        },
      });
      setRideData(response.data); // Update this line to set the correct data in the state
    } catch (error) {
      console.log(error);
    }
  };

  fetchData();
}, []);

// Create an empty object to store daily totals
const dailyTotals = {};

// Loop through the input data
rideData.forEach(item => {
  const date = item.driverHistory.date.split('T')[0]; // Extract the date without the time
  const price = parseFloat(item.details.price); // Convert price to a float

  if (!dailyTotals[date]) {
    dailyTotals[date] = 0; // Initialize the total for the day
  }

  dailyTotals[date] += price; // Add the price to the day's total
});

// Convert the daily totals object into an array of objects
const dailyTotalsArray = Object.keys(dailyTotals).map(date => ({
  name : date,
  Total : dailyTotals[date]
}));


  return (
    <div className="chart">
    <div className="title">{"Day by Day Transactions"}<h4 style={{color:"red"}}> {dailyTotalsArray.length === 0 ? "Loading..." : ''}</h4></div>
      <ResponsiveContainer width="100%" aspect={aspect}>
      <AreaChart width={730} height={250} data={dailyTotalsArray}
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    
  </defs>
  <XAxis dataKey="name"  stroke="gray"/>
  {/* <YAxis /> */}
  <CartesianGrid strokeDasharray="3 3"  className="chartGrid"/>
  <Tooltip />
  <Area type="monotone" dataKey="Total" stroke="#8884d8" fillOpacity={1} fill="url(#total)" />
  {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
</AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart