import "./navbar.scss"

// import SearchOutlinedIcon from "@mui/icons-material"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import React ,{useState,useEffect} from "react";
import axios from "axios";
const Navbar = () => {
  const getCurrentURLLastPart = () => {
    const pathParts = window.location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    return lastPart;
  };
  return (
    <div className='navbar'>
     <div className="wrapper">
    {getCurrentURLLastPart() ==''  ?  <div className="search">
        <input type="text" placeholder="Search...."/>
        <SearchOutlinedIcon/>
      </div>  : ''}
      <div className="items">
        {/* <div className="item">
            <LanguageOutlinedIcon className="icon"/>
            English
        </div> */}

        {/* <div className="item">
            <DarkModeOutlinedIcon className="icon"/>
            
        </div>

        <div className="item">
            <FullscreenExitOutlinedIcon className="icon"/>
    
        </div>
        <div className="item">
            <NotificationsNoneOutlinedIcon className="icon"/>
            <div className="counter">1</div>
        </div>
        <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon"/>
            <div className="counter">2</div>
        </div>
        <div className="item">
            <ListOutlinedIcon className="icon"/>
          
        </div> */}

        <div className="item" style={{marginLeft:20}}>
            <img 
                src="https://static.vecteezy.com/system/resources/previews/009/636/683/original/admin-3d-illustration-icon-png.png" 
                alt="img" className="avatar"
                style={{cursor:"pointer"}}
            />
          
        </div>

      </div>
     </div>
    </div>
  )
}

export default Navbar